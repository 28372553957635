<template>
  <v-row>
    <v-col>
      <v-card-text class="card-title ma-0">{{ $t('menu.dashBoard') }}</v-card-text>
    </v-col>
    <v-col cols="12">
      <SelectBoxClinic @updateSelect="updateSelectClinic" />
    </v-col>
    <v-col cols="12">
      <PatientStatus :data="resData" />

      <!-- <EligibleForBilling />
      <span class="_gap"></span> -->

      <!-- <EnrolledProgram /> -->
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SelectBoxClinic from "@/components/commonV2/ui/SelectBoxClinic.vue";

import PatientStatus from "./comp/PatientStatus.vue";
import EligibleForBilling from "./comp/EligibleForBilling.vue";
import EnrolledProgram from "./comp/EnrolledProgram.vue";

const modelService = require("@/utils/dataModel");
const formatService = require("@/utils/format.js");

export default {
  components: {
    SelectBoxClinic,
    PatientStatus,
    EligibleForBilling,
    EnrolledProgram,
  },
  data() {
    return {
      resData: {
        eligible: {
          value: 0,
          label: "Eligible",
          textColor: "text-info",
        },
        enrolled: {
          value: 0,
          series: [30],
          label: "Enrolled",
          textColor: "text-info",
          colors: ["#5e5ce6"],
        },
        consent: {
          value: 0,
          series: [10],
          label: "Consent",
          textColor: "text-info",
          colors: ["#FF8A48"],
        },
        consentPending: {
          value: 0,
          series: [70],
          label: "Pending",
          textColor: "text-warning",
          colors: ["gray"],
        },
      },
      reqData: {
        endpoint: "dashBoard",
        hospital_id: 0,
      },
      userData: modelService.staffModel(),
    };
  },
  mounted() {
    if (this.meInfo.userType !== "super") {
      this.reqData.hospital_id = this.meInfo.hospital_id;
    }
    this.getData();
  },
  methods: {
    async getData() {
      this.getDataQuery(this.reqData).then((res) => {
        if (res === undefined) return;
        this.resData.eligible.value = res.eligible;
        this.resData.enrolled.value = res.enrolled;
        this.resData.enrolled.series = res.enrolled === 0? [0]:[((res.enrolled / res.eligible) * 100).toFixed(1)];
        this.resData.consent.value = res.consent;
        this.resData.consent.series = res.consent === 0? [0]:[((res.consent / res.eligible) * 100).toFixed(1)];
        this.resData.consentPending.value = res.consentPending;
        this.resData.consentPending.series = res.consentPending === 0? [0]:[((res.consentPending / res.eligible) * 100).toFixed(1)];
        console.log("res", this.resData);
      });
    },
    updateSelectClinic(e) {
      this.reqData.hospital_id = e === "" ? 0 : e;
      this.getData();
    },
    ...mapActions(["getDataQuery"]),
  },
  computed: {
    ...mapGetters(["meInfo"]),
  },
};
</script>
<style lang="scss">
.bigFont {
  padding: 20px;
  font-size: 30px;
  color: blueviolet;
  text-align: center;
}
.middleFont {
  font-size: 16px;
  color: black;
  text-align: center;
}
.colorFont {
  color: blueviolet;
}
</style>