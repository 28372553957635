<template>
  <base-card>
    <v-list-item>
      <v-list-item-content>
        <div class="body-2 mb-0 text--disabled">{{ data.label }}</div>
        <v-list-item-title class="headline mb-1 font-weight-bold">
          <animated-number v-if="data" :value="data.value" :formatValue="formatToPrice" :duration="duration" />
        </v-list-item-title>
        <v-list-item-subtitle>
          <!-- <div class="d-flex flex-wrap">
            <v-sheet
              height="20"
              width="20"
            >
              <v-icon class="info--text caption">mdi-arrow-top-right</v-icon>
            </v-sheet>
            <span :class="`font-weight-bold ${data.textColor}`">14%</span>
          </div> -->
        </v-list-item-subtitle>
      </v-list-item-content>

      <apexchart
        width="100"
        height="150"
        type="radialBar"
        :options="jmPieChartOne.chartOptions"
        :series="data.series"
        :colors="data.colors"
      />
    </v-list-item>
  </base-card>
</template>
<script>
import { jmPieChartOne } from "@/data/jobManagement";
import AnimatedNumber from 'animated-number-vue';
export default {
  components: {
    AnimatedNumber,
  },
  props: {
    data: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      value: 1000,
      duration: 1000,
      jmPieChartOne
    };
  },
  methods: {
    formatToPrice(value) {
      return `${Number(value).toFixed(0)}`;
    },
  },
};
</script>
<style lang="">
</style>