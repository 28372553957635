<template>
  <v-row>
      <v-col cols="12" md="3">
        <KPICard
          :data="data.eligible"
          chip-color="primary lighten-5"
          title="Eligible"
          amount="0"
          footer-text="-"
          footer-class="primary--text"
          card-icon="mdi-chart-line-variant"
          color-icon="primary--text"
        />
      </v-col>
      <v-col cols="12" md="3">
        <StatusCard :data="data.enrolled" />
      </v-col>
      <v-col cols="12" md="3">
        <StatusCard :data="data.consent" />
      </v-col>
      <v-col cols="12" md="3">
        <StatusCard :data="data.consentPending" />
      </v-col>
    </v-row>
</template>
<script>
import AnimatedNumber from 'animated-number-vue';
import KPICard from './comp/KPICard.vue';
import StatusCard from './comp/StatusCard.vue';
export default {
  components: {
    AnimatedNumber,
    KPICard,
    StatusCard
  },
  props: {
    data: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      value: 1000,
      duration: 1000,
    };
  },
  methods: {
    formatToPrice(value) {
      return `${Number(value).toFixed(0)}`;
    },
  },
};
</script>
<style lang="">
</style>