<template>
  <v-card>
    <v-card-title>Enrolled Program</v-card-title>
    <v-divider></v-divider>
    <v-row>
      <v-col>
        <div class="middleFont">CCM</div>
        <v-divider></v-divider>
        <div class="bigFont">
          <animated-number :value="sample.ccm" :formatValue="formatToPrice" :duration="duration" />
        </div>
        <v-divider></v-divider>
      </v-col>
      <v-col>
        <div class="middleFont">CCM</div>
        <v-divider></v-divider>
        <div class="bigFont">
          <animated-number :value="sample.rpm" :formatValue="formatToPrice" :duration="duration" />
        </div>
        <v-divider></v-divider>
      </v-col>
      <v-col>
        <div class="middleFont">Nutrition Consultation</div>
        <v-divider></v-divider>
        <div class="bigFont">
          <animated-number :value="sample.nutrition" :formatValue="formatToPrice" :duration="duration" />
        </div>
        <v-divider></v-divider>
      </v-col>
      <v-col>
        <div class="middleFont">Cognitive Test</div>
        <v-divider></v-divider>
        <div class="bigFont">
          <animated-number :value="sample.cognitive" :formatValue="formatToPrice" :duration="duration" />
        </div>
        <v-divider></v-divider>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import AnimatedNumber from 'animated-number-vue';
export default {
  components: {
    AnimatedNumber,
  },
  props: {
    data: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      value: 1000,
      duration: 1000,
      sample: { 
        ccm: 0,
        rpm: 0,
        nutrition: 0,
        cognitive: 0,
      }
    };
  },
  methods: {
    formatToPrice(value) {
      return `${Number(value).toFixed(0)}`;
    },
  },
};
</script>
<style lang="">
</style>